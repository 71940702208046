import React, { useContext, useEffect, useRef } from "react"
import useStoryblok from "../lib/storyblok"
import { render, NODE_PARAGRAPH } from 'storyblok-rich-text-react-renderer'
import Slider from "react-slick"
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from "../lib/gatsby_storyblok_image"
import { AnimationOnScroll } from 'react-animation-on-scroll'

import "../styles/portfoliowork.less" 
import PortfolioIntro from "../components/blocks/Portfolio/PortfolioIntro"
import ContentBlock from "../components/common/ContentBlock/ContentBlock"
import Seo from "../components/services/seo"
import { CurrentPageContext } from "../context"
import Breadcrumbs from "../components/common/Breadcrumbs/Breadcrumbs"
import { Link } from "gatsby"

const PortfolioWork = ({ pageContext, location }) => {

  const sliderRef = useRef(null);

  const imageFinalQuality = 95;
  let story = pageContext.story
  story = useStoryblok(story, location)

  const { currentPage, setCurrentPage} = useContext(CurrentPageContext);
  useEffect(() => {
    setCurrentPage([{
      slug: '/interior-design-portfolio/',
      name: 'Portfolio'
    },
    {
      slug: '',
      name: story.name
    }])
  }, [setCurrentPage, story.name])


  let allTags = pageContext.tags

  let project = story.content
  project.items = project.related_works

  let filteredTags = [];
  if (allTags) {
    filteredTags = allTags

    filteredTags = filteredTags.map((p) => {
      const content = p.node.content
      const newContent = typeof content === 'string' ? JSON.parse(content) : content
      p.node.content = newContent
      return p.node
    })
  }

  project['tagsSelected'] = filteredTags.filter(t => (project.tags && project.tags.includes(t.uuid)));

  const slickSettings = {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            dots: false
          }
        }
      ]
  }

  let fluidPropsBanner;
  if (project.image_banner){
    fluidPropsBanner = getFluidGatsbyImage(project.image_banner.filename, {
    })
  }

  const backgroundImageStyle = {
    backgroundImage: 'url(' + project.image_banner.filename.replace('//a.storyblok.com/f/139398', '//assets.blackandmilk.co.uk') + ')',
  }
 
  const contentBlok = {
    button_link: "contact/",
    button_text: (project.is_completed) ? 'Say Hello' : '@BLACKANDMILK',
    name: (project.is_completed) ? 'In Pursuit of magic' : 'Stay up to Date',
    header_style: 'italic',
    header_font: 'small_black',
    header_margin: 'large',
    type: 'text_only',
    margin_top: '60',
    max_width: '380',
    image: {
      filename: ''
    },
    text: {
      content: [{
        type: "paragraph",
        content: [{
          type: 'text',
          text: 'To learn more about how we can bring your vision to life and your project journey with Black and Milk, please feel free to get in touch'
        }]
      }],

      type: 'doc'
    }
  }
  let allImages = [];
  if (project.image_banner && project.image_banner.filename){
    allImages.push(project.image_banner);
  }
  project.detail_items.map(item => {
    if (item.image1 && item.image1.filename){
      allImages.push(item.image1);
    }
    if (item.image2 && item.image2.filename){
      allImages.push(item.image2);
    }
    return item;
  })

  allImages = allImages.map(item => {
    return {
      "@type": "imageObject",
      "url": item.filename.replace('//a.storyblok.com/f/139398', '//assets.blackandmilk.co.uk')
    }
  });

  const siteData = pageContext.siteInfo;
  const schema_json = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": siteData.name,
    "url": location.href,
    "logo": siteData.logo,
    "image": allImages,
    "telephone": siteData.phone, 
    "address": {
      "@type": "PostalAddress",
      "streetAddress": siteData.address,
      "addressLocality": siteData.city,
      "postalCode": siteData.postalCode,
      "addressCountry": siteData.country
    }
  };

  const schema = JSON.stringify(schema_json);

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  return (
  <>
    <Seo title={project.title_meta} description={project.description_meta} schemaMarkup={schema} image={project.image_banner.filename} url={location.href} />
    {project.image_banner &&
    <div className='at-teaser at-projects__detailed-teaser center-text-banner shifted-header'>
        <Img fluid={fluidPropsBanner} className={'at-teaser__img'} />
        <div className="at-teaser__content">
          <h1 className={'at-teaser__title'}>{project.name}</h1>
          <div className={'at-teaser__location'}>{project.location}</div>
        </div>
        <div style={backgroundImageStyle} className='at-teaser__background'></div> 
    </div>
    }
    <AnimationOnScroll animateIn="animate__fadeIn">
    <div className="at-projects__info">
      <div className="at-projects__info-wrapper">
      <Breadcrumbs />        
        <div className="at-projects__info-title">{project.description_title}</div>
        <div className="at-projects__info-right">
          {project.location &&
            <div className="at-projects__info-one"><span className="at-projects__info-one-label">Location:</span><span className="at-projects__info-one-value">{project.location}</span></div>
          }
          {project.type &&
            <div className="at-projects__info-one"><span className="at-projects__info-one-label">Project Type:</span><span className="at-projects__info-one-value">{project.type}</span></div>
          }
          {project.sqft &&
            <div className="at-projects__info-one"><span className="at-projects__info-one-label">Sqft:</span><span className="at-projects__info-one-value">{project.sqft}</span></div>
          }
          {project.services &&
            <div className="at-projects__info-one"><span className="at-projects__info-one-label">Services:</span><span className="at-projects__info-one-value">{project.services}</span></div>
          }
        </div>      
 
        <div className="at-projects__info-left">
            <div className="at-projects__info-description">
              <div className="at-projects__info-description-column">
                {
                  render(
                    project.description_column_one,
                    {
                      nodeResolvers: {
                        [NODE_PARAGRAPH]: (children) => {
                          children && children.map(item => {
                            if (item.type === 'a'){
                              if (item.props.href.indexOf("/") === 0){
                                item.props.rel = 'follow';
                                item.props.href = item.props.href.replace('/tags/', '/');
                                item.props.href = item.props.href.replace('/journal-posts/', '/');
                                if (item.props.href.lastIndexOf("/") !==  (item.props.href.length - 1)){
                                  item.props.href = item.props.href + "/";
                                }
                              }else{
                                item.props.rel = 'nofollow';
                              }  
                            } 
                            return true;
                          });
                          return <p>{children}</p>;
                        }
                      }               
                    }                    
                  )                
                }              
              </div>
              <div className="at-projects__info-description-column">
                {
                  render(
                    project.description_column_two,
                    {
                      nodeResolvers: {
                        [NODE_PARAGRAPH]: (children) => {
                          children && children.map(item => {
                            if (item.type === 'a'){
                              if (item.props.href.indexOf("/") === 0){
                                item.props.rel = 'follow';
                                if (item.props.href.lastIndexOf("/") !==  (item.props.href.length - 1)){
                                  item.props.href = item.props.href + "/";
                                }
                              }else{
                                item.props.rel = 'nofollow';
                              }  
                            } 
                            return true;
                          });
                          return children;
                        }
                      }               
                    }                    
                  )                
                } 
              </div> 
            </div>
        </div>
      </div>
    </div>
    </AnimationOnScroll>
    <AnimationOnScroll animateIn="animate__fadeIn">
    <div className="at-projects">
      <Slider {...slickSettings} ref={sliderRef} className="at-projects__scroller">
        {
              project.detail_items.map(d_item => {
                let fluidPropsFirst, fluidPropsSecond;
                if (d_item.image1.filename){
                   fluidPropsFirst = getFluidGatsbyImage(d_item.image1.filename, {
                    maxWidth: 1480,
                    quality: imageFinalQuality
                   })
                }
                if (d_item.image2.filename){
                   fluidPropsSecond = getFluidGatsbyImage(d_item.image2.filename, {
                    maxWidth: 1480,
                    quality: imageFinalQuality
                   })
                }
                  if (d_item.type === "horizontal") {
                    return (
                      <div
                        key={d_item.id}
                        className="at-projects__one horizontal"
                        onClick={goToNextSlide}
                      >
                          <div className="at-projects__one-wrapper">
                            <Img fluid={fluidPropsFirst} loading={'auto'} className={'at-projects__one-image'} />
                          </div>
                      </div>
                    )
                  } else if (d_item.type === "vertical_left_and_text") {

                    return (
                      <div
                        key={d_item.id}
                        className="at-projects__one vertical_left_and_text"
                        onClick={goToNextSlide}
                      >
                          <div className="at-projects__one-wrapper">
                              <Img fluid={fluidPropsFirst} loading={'auto'} className={'at-projects__one-image'} />
                              <div className="at-projects__one-text">
                                  { render(d_item.text) }
                              </div>
                          </div>
                      </div>
                    )
                  } else if (d_item.type === "two_vertical_images") {
                    return (
                      <div
                        key={d_item.id}
                        className="at-projects__one two_vertical_images"
                        onClick={goToNextSlide}
                      >
                          <div className="at-projects__one-wrapper">
                              <Img fluid={fluidPropsFirst} loading={'auto'} className={'at-projects__one-image'} />
                              <Img fluid={fluidPropsSecond} loading={'auto'} className={'at-projects__one-image'} />
                          </div>
                      </div>
                    )
                  } else if (d_item.type === "text_and_vertical_right") {
                    return (
                      <div
                        key={d_item.id}
                        className="at-projects__one text_and_vertical_right"
                        onClick={goToNextSlide}
                      >
                          <div className="at-projects__one-wrapper">
                              <div className="at-projects__one-text">
                                  { render(d_item.text) }
                              </div>
                              <Img fluid={fluidPropsFirst} loading={'auto'} className={'at-projects__one-image'} />
                          </div>
                      </div>
                    )
                  }else{
                    return (<div></div>)
                  }
              })
        }
      </Slider>

      <div className="at-projects__scroller-mobile">
        {
              project.detail_items.map((d_item, index) => {
                let fluidPropsFirst, fluidPropsSecond;
                if (d_item.image1.filename){
                   fluidPropsFirst = getFluidGatsbyImage(d_item.image1.filename, {
                    maxWidth: 1024,
                    quality: imageFinalQuality
                   })
                }
                if (d_item.image2.filename){
                   fluidPropsSecond = getFluidGatsbyImage(d_item.image2.filename, {
                    maxWidth: 1024,
                    quality: imageFinalQuality
                   })
                }
                  if (d_item.type === "horizontal") {
                    return (
                      <div
                        key={index}
                        className="at-projects__one horizontal"
                      >
                          <div className="at-projects__one-wrapper">
                            <Img fluid={fluidPropsFirst} className={'at-projects__one-image'} />
                          </div>
                      </div>
                    )
                  } else if (d_item.type === "vertical_left_and_text") {

                    return (
                      <div
                        key={index}
                        className="at-projects__one vertical_left_and_text"
                      >
                          <div className="at-projects__one-wrapper">
                              <Img fluid={fluidPropsFirst} className={'at-projects__one-image'} />
                              <div className="at-projects__one-text">
                                  { render(d_item.text) }
                              </div>
                          </div>
                      </div>
                    )
                  } else if (d_item.type === "two_vertical_images") {
                    return (
                      <div
                        key={index}
                        className="at-projects__one two_vertical_images"
                      >
                          <div className="at-projects__one-wrapper">
                              <Img fluid={fluidPropsFirst} className={'at-projects__one-image'} />
                              <Img fluid={fluidPropsSecond} className={'at-projects__one-image'} />
                          </div>
                      </div>
                    )
                  } else if (d_item.type === "text_and_vertical_right") {
                    return (
                      <div
                        key={index}
                        className="at-projects__one text_and_vertical_right"
                      >
                          <div className="at-projects__one-wrapper">
                              <div className="at-projects__one-text">
                                  { render(d_item.text) }
                              </div>
                              <Img fluid={fluidPropsFirst} className={'at-projects__one-image'} />
                          </div>
                      </div>
                    )
                  }else{
                    return (<div></div>)
                  }
              })
        }
      </div>
    </div>
    </AnimationOnScroll>

    <ContentBlock blok={contentBlok}/>

    {project.related_works.length > 0 &&
    <div className="at-projects__also">
      <h3 className="at-subheader">You may also like</h3>
      <div className="at-projects__also-wrapper">
        <PortfolioIntro blok={project} hideViewAll={true} />
      </div>
    </div>
    }

    <div className="max-w-8xl flex my-20 mx-auto">
      <div className="w-full mx-5 md:mx-20">
      {project.tagsSelected && project.tagsSelected.map(tag => {
        return <Link to={"/" + tag.slug + "/"} className="font-sans text-sm leading-6 not-italic font-light text-bam-brown uppercase cursor-pointer mr-3 last:mr-0">{tag.content.name}</Link>
      })}
      </div>
    </div>        
  </>
  )
}

export default PortfolioWork
